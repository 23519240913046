exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-contact-thank-you-index-jsx": () => import("./../../../src/pages/contact/thank-you/index.jsx" /* webpackChunkName: "component---src-pages-contact-thank-you-index-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-getting-started-index-jsx": () => import("./../../../src/pages/getting-started/index.jsx" /* webpackChunkName: "component---src-pages-getting-started-index-jsx" */),
  "component---src-pages-getting-started-thank-you-index-jsx": () => import("./../../../src/pages/getting-started/thank-you/index.jsx" /* webpackChunkName: "component---src-pages-getting-started-thank-you-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-landing-medical-billing-made-simple-index-jsx": () => import("./../../../src/pages/landing/medical-billing-made-simple/index.jsx" /* webpackChunkName: "component---src-pages-landing-medical-billing-made-simple-index-jsx" */),
  "component---src-pages-landing-no-gap-billing-made-simple-index-jsx": () => import("./../../../src/pages/landing/no-gap-billing-made-simple/index.jsx" /* webpackChunkName: "component---src-pages-landing-no-gap-billing-made-simple-index-jsx" */),
  "component---src-pages-onboarding-index-jsx": () => import("./../../../src/pages/onboarding/index.jsx" /* webpackChunkName: "component---src-pages-onboarding-index-jsx" */),
  "component---src-pages-pricing-index-jsx": () => import("./../../../src/pages/pricing/index.jsx" /* webpackChunkName: "component---src-pages-pricing-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-security-index-jsx": () => import("./../../../src/pages/security/index.jsx" /* webpackChunkName: "component---src-pages-security-index-jsx" */),
  "component---src-pages-solutions-anaesthetists-index-jsx": () => import("./../../../src/pages/solutions/anaesthetists/index.jsx" /* webpackChunkName: "component---src-pages-solutions-anaesthetists-index-jsx" */),
  "component---src-pages-solutions-physicians-index-jsx": () => import("./../../../src/pages/solutions/physicians/index.jsx" /* webpackChunkName: "component---src-pages-solutions-physicians-index-jsx" */),
  "component---src-pages-solutions-proceduralists-index-jsx": () => import("./../../../src/pages/solutions/proceduralists/index.jsx" /* webpackChunkName: "component---src-pages-solutions-proceduralists-index-jsx" */),
  "component---src-pages-solutions-psychiatrists-index-jsx": () => import("./../../../src/pages/solutions/psychiatrists/index.jsx" /* webpackChunkName: "component---src-pages-solutions-psychiatrists-index-jsx" */),
  "component---src-pages-terms-index-jsx": () => import("./../../../src/pages/terms/index.jsx" /* webpackChunkName: "component---src-pages-terms-index-jsx" */)
}

